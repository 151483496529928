import * as React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { graphql } from "gatsby";

import Layout from "../layouts/default-layout";

import PageHead from "../components/pageHead";
import BodyBlockContent from "../components/bodyBlockContent";

const LegalPage = ({ path, data }) => {
  const intl = useIntl()
  const content = data.sanityLegal
  const title = content.title ? content.title[intl.locale] : '';
  const metaTitle = content.meta_title ? content.meta_title[intl.locale] : title;
  const metaDescription = content.meta_description ? content.meta_description[intl.locale] : content.subline ? content.subline[intl.locale] : null;  
  return (
    <Layout textWhite={content.lightHeader} title={metaTitle} description={metaDescription} noIndex={content.meta_noindex} path={path}>
      <PageHead
        headline={title}
        subline={content.subline && content.subline[intl.locale]}
        customClass="mb-9 medium:mb-24"
        backgroundImage={content.poster ? content.poster.asset.url : false}
        textWhite={content.lightHeader}
      />

      <BodyBlockContent data={content._rawBody[intl.locale]} />
    
    </Layout>
  );
};

export default LegalPage;


export const query = graphql`
  query legal {
    sanityLegal(_id: {eq: "legal"}) {
      title {
        de
        en
      }
      subline {
        de
        en
      }
      meta_title {
        de
        en
      }
      meta_description {
        de
        en
      }
      meta_noindex
      poster {
        asset {
          url
          gatsbyImageData
        }
      }
      lightHeader,
      _rawBody
    }
  }
`
